<section id="purchasesView">
  <div class="section-content">
    <div class="main-content">
      <div class="container-without-bg" nice-scroll>
        <div class="container" style="margin-top: 20vh">
          <div class="row ml0 mr0">
            <div class="col-md-12" align="center">
              <div>
                <img
                  src="./assets/images/logo.svg"
                  alt="SoStereo Logo"
                  class="logo sostereo-logo"
                />
              </div>
              <h1>{{ error || 'Page not found' }}</h1>
              <p>{{ message || 'Sorry, but the page you were trying to view does not exist.' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
